import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GalleryTest from './components/GalleryTest';
import CheckoutForm from './components/CheckoutForm';
import BottomNavigation from './components/BottomNavigation';
import HomePage from './pages/HomePage';
import Components from './pages/Components';
import Assets from './pages/Assets';
import Characteristics from './pages/Characteristics';
import CheckoutPage from './pages/CheckoutPage';
import CreatePostPage from './pages/CreatePostPage';
import UgcPage from './pages/UgcPage';
import IAssetPage from './pages/IAssetPage';
import ProfilePage from './pages/ProfilePage';
import Login from './pages/Login';
import Register from './pages/Register';
import MainLayout from './MainLayout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import store from './stores';
import { Provider } from 'react-redux';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Elements stripe={stripePromise}>
          <MainLayout>
            <Routes>
              <Route path="/" exact element={<HomePage />} />
              <Route path="/home" exact element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" component={<Register />} />
              <Route path="/components" element={<Components />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/characteristics" element={<Characteristics />} />
              <Route path="/create" component={<CreatePostPage />} />
              <Route path="/ugc/:ugcId" element={<UgcPage />} />
              <Route path="/iasset/:iassetId" element={<IAssetPage />} />
              <Route path="/profile/:userId" element={<ProfilePage />} />
              <Route path="/checkout" element={<CheckoutPage />}>
                {/* <CheckoutForm cartItems={cartItems} /> */}
              </Route>
            </Routes>
          </MainLayout>
          <BottomNavigation />
        </Elements>
      </Router>
    </Provider>
  );
};

export default App;
