import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function ProfilePage() {
  const navigate = useNavigate();
  const goToPost = () => {
    // navigate.push({
    //   pathname: "/post",
    // });
  };

  const ProfileInfo = ({ userInfo }) => {
    const {
      username,
      bio,
      profilePic,
      postsCount,
      followersCount,
      followingCount,
    } = userInfo;

    return (
      <div className="px-4 md:px-16 py-1 flex flex-col md:flex-row items-center">
        <img
          className="rounded-full w-20 h-20 md:w-24 md:h-24 mr-0 md:mr-5 mb-3 md:mb-0"
          src={profilePic}
          alt={username}
        />
        <div className="md:flex-grow">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg md:text-xl font-bold">{username}</h2>
            <button className="px-3 py-1 md:px-5 md:py-2 border border-gray-300 rounded-md text-sm font-bold">
              Edit Profile
            </button>
          </div>
          <p className="mb-3">{bio}</p>
          <div className="flex justify-between md:justify-start text-sm font-medium">
            <span className="mr-4">{postsCount} posts</span>
            <span className="mr-4">{followersCount} followers</span>
            <span>{followingCount} following</span>
          </div>
        </div>
      </div>
    );
  };

  const mockPostsData = [
    {
      id: '1',
      image: 'https://via.placeholder.com/300',
      description: 'Post 1',
    },
    {
      id: '2',
      image: 'https://via.placeholder.com/300',
      description: 'Post 2',
    },
    {
      id: '3',
      image: 'https://via.placeholder.com/300',
      description: 'Post 3',
    },
    {
      id: '4',
      image: 'https://via.placeholder.com/300',
      description: 'Post 4',
    },
    {
      id: '5',
      image: 'https://via.placeholder.com/300',
      description: 'Post 5',
    },
    {
      id: '6',
      image: 'https://via.placeholder.com/300',
      description: 'Post 6',
    },
    // Add as many posts as needed
  ];
  const ProfileNavigation = () => {
    return (
      <div className="flex justify-around border-t border-gray-300 mt-4 pt-2">
        <button className="text-gray-500 text-sm sm:text-base md:text-lg font-arial py-1 px-2 sm:py-2 sm:px-3 md:py-3 md:px-4 rounded-md mr-2 sm:mr-3 md:mr-4 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600">
          POSTS
        </button>
        <button className="text-gray-500 text-sm sm:text-base md:text-lg font-arial py-1 px-2 sm:py-2 sm:px-3 md:py-3 md:px-4 rounded-md mr-2 sm:mr-3 md:mr-4 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600">
          TAGGED
        </button>
      </div>
    );
  };
  const PostsGrid = ({ posts }) => {
    return (
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-1 sm:gap-2 md:gap-3 p-2 sm:p-3 md:p-4 mt-4">
        {posts.map((post) => (
          <div key={post.id}>
            <img
              className="object-cover w-full h-full cursor-pointer"
              src={post.image}
              alt={post.description}
              onClick={goToPost}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center p-4">
      <ProfileInfo
        userInfo={{
          username: 'john_doe',
          bio: 'Travel enthusiast',
          profilePic: 'https://via.placeholder.com/640',
          postsCount: 231,
          followersCount: 5421,
          followingCount: 162,
        }}
      />
      <ProfileNavigation />
      <PostsGrid posts={mockPostsData} />
    </div>
  );
}

export default ProfilePage;
