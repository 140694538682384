import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getAllAssetsByCreatorId,
  getAsset,
  publishAsset,
  unpublishAsset,
} from '../server/assets';

function AssetLibrary() {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [publishedAssets, setPublishedAssets] = useState([]);
  const [unpublishedAssets, setUnpublishedAssets] = useState([]);

  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (userId && userId !== '') {
      console.log('init 5');
      init();
    }
  }, [userId]);

  useEffect(() => {
    if (!selectedAsset || selectedAsset.content) return;
    loadSelectedAsset();
  }, [selectedAsset]);

  useEffect(() => {
    const published = assets.filter((a) => a.published);
    const unpublished = assets.filter((a) => !a.published);
    setPublishedAssets(published);
    setUnpublishedAssets(unpublished);
  }, [assets]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const assetName = searchParams.get('name');
    if (assetName) {
      const asset = assets.find(
        (a) => a.name.toLowerCase() === assetName.toLowerCase(),
      );
      if (asset) {
        setSelectedAsset(asset);
      } else {
        console.log(`Asset with name "${assetName}" not found`);
        // You can set an error state here if needed
      }
    }
  }, [location, assets]);

  const getAssets = async () => {
    try {
      if (!userId) return;
      const response = await getAllAssetsByCreatorId(userId);
      console.log({ response });
      if (response.data) {
        let assets = response.data.data;
        assets = assets.map((a) => {
          return {
            id: a._id,
            name: a.name,
            assetPreviewUrl: a.assetPreviewUrl,
            published: a.published,
            public: a.public,
          };
        });
        // console.log({ assets });
        setAssets(assets);
        setSelectedAsset(assets[0]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const loadSelectedAsset = async () => {
    if (!selectedAsset) return;
    try {
      const response = await getAsset(selectedAsset.id, token);
      if (response.data) {
        const asset = response.data.data;
        setSelectedAsset((prevAsset) => ({
          ...prevAsset,
          content: asset.content,
        }));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handlePublish = async () => {
    if (!selectedAsset) return;
    try {
      const response = await publishAsset(selectedAsset.id, token);
      // console.log({ response });
      if (response.data) {
        await getAssets();
      }
    } catch (error) {
      console.error('Error publishing asset:', error);
    }
  };

  const handleUnpublish = async () => {
    if (!selectedAsset) return;
    try {
      const response = await unpublishAsset(selectedAsset.id, token);
      // console.log({ response });
      if (response.data) {
        // Refresh the asset list
        await getAssets();
      }
    } catch (error) {
      console.error('Error unpublishing asset:', error);
    }
  };

  async function init() {
    await getAssets();
  }

  const AssetPreview = () => {
    const [selectedCharacteristic, setSelectedCharacteristic] = useState(null);
    const [publishing, setPublishing] = useState(false);

    if (!selectedAsset) return null;

    const characteristics = selectedAsset.content
      ? selectedAsset.content.map((item) => {
          const characteristic = item.characteristic.id;
          const textureComponent = item.characteristic.components.find(
            (comp) => comp.type === 'texture',
          );
          return {
            ...characteristic,
            previewImg: textureComponent ? textureComponent.rawFileUrl : null,
          };
        })
      : [];

    const loadSelectedChar = (char) => {
      setSelectedCharacteristic(char);
    };

    useEffect(() => {
      if (selectedAsset.content) {
        setSelectedCharacteristic(characteristics[0]);
      }
    }, [selectedAsset]);

    const onPublish = async () => {
      setPublishing(true);
      await handlePublish();
      setPublishing(false);
    };

    const onUnpublish = async () => {
      setPublishing(true);
      await handleUnpublish();
      setPublishing(false);
    };

    return (
      <div className="p-4 bg-white rounded-lg shadow-lg">
        {!selectedAsset.published && (
          <div className="mb-4">
            <button
              onClick={onPublish}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={publishing}
            >
              {publishing ? 'Publishing...' : 'Publish'}
            </button>
          </div>
        )}
        {selectedAsset.published && (
          <div className="mb-4">
            <button
              onClick={onUnpublish}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              disabled={publishing}
            >
              {publishing ? 'UnPublishing...' : 'UnPublish'}
            </button>
          </div>
        )}
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 lg:w-1/4 bg-gray-50 p-2 rounded-lg mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-3">Characteristics</h3>
            {selectedAsset.content ? (
              <ul className="divide-y divide-gray-200">
                {characteristics.map((char, index) => (
                  <li
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-blue-50 ${
                      selectedCharacteristic &&
                      char._id === selectedCharacteristic._id
                        ? 'bg-blue-100 font-semibold'
                        : ''
                    }`}
                    onClick={() => loadSelectedChar(char)}
                  >
                    {char.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-400 italic">Loading characteristics...</p>
            )}
          </div>

          {/* Preview of selected characteristic */}
          <div className="flex-grow p-4">
            {selectedCharacteristic ? (
              <div className="rounded-lg border border-gray-200 shadow-sm p-4">
                <h4 className="text-xl font-semibold mb-2">
                  {selectedCharacteristic.name}
                </h4>
                <div className="flex flex-col md:flex-row">
                  <img
                    className="h-[150px] w-[150px] rounded-lg border border-gray-300 mr-4"
                    src={selectedCharacteristic.previewImg}
                    alt={selectedCharacteristic.name}
                  />
                  <p className="text-gray-700 mt-2 md:mt-0">
                    {selectedCharacteristic.description}
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-gray-400 italic">
                Select a characteristic to preview
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const AssetList = ({ assets, title }) => (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <ul className="divide-y divide-gray-200">
        {assets.map((a) => (
          <li
            key={a.id}
            className={`p-2 cursor-pointer hover:bg-blue-50 ${
              selectedAsset && selectedAsset.id === a.id
                ? 'bg-blue-100 font-semibold'
                : ''
            }`}
            onClick={() => setSelectedAsset(a)}
          >
            {a.name}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 lg:w-1/4 bg-gray-50 p-2 rounded-lg">
        <h2 className="text-xl font-semibold mb-3">Assets List</h2>
        <AssetList assets={publishedAssets} title="Published Assets" />
        <AssetList assets={unpublishedAssets} title="Unpublished Assets" />
      </div>

      <div className="flex-grow p-4">
        {selectedAsset ? (
          <div className="rounded-lg border border-gray-200 shadow-sm p-4">
            <AssetPreview asset={selectedAsset} />
          </div>
        ) : (
          <div className="text-gray-400 italic">Select an asset to preview</div>
        )}
      </div>
    </div>
  );
}

export default AssetLibrary;
