import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AssetBuilder from '../pages/AssetBuilder';
import AssetLibrary from '../pages/AssetLibrary';

export default function Assets() {
  const [activeTab, setActiveTab] = useState('builder');

  const location = useLocation();

  useEffect(() => {
    console.log(location);
    const searchParams = new URLSearchParams(location.search);
    const assetName = searchParams.get('name');
    if (assetName) {
      setActiveTab('library');
    }
  }, [location]);

  return (
    <div className="p-4 border-2 border-gray-300 border-dashed rounded-lg dark:border-gray-700 h-full shadow-lg">
      <div className="border-b-2 border-gray-300 mb-4">
        {/* Tab Buttons */}
        <button
          onClick={() => setActiveTab('builder')}
          className={`px-6 py-2 text-sm font-medium transition-colors duration-200 ease-in-out ${
            activeTab === 'builder'
              ? 'border-b-4 border-blue-500 text-blue-600'
              : 'text-gray-600 hover:text-blue-500'
          }`}
        >
          Builder
        </button>
        <button
          onClick={() => setActiveTab('library')}
          className={`px-6 py-2 text-sm font-medium transition-colors duration-200 ease-in-out ${
            activeTab === 'library'
              ? 'border-b-4 border-blue-500 text-blue-600'
              : 'text-gray-600 hover:text-blue-500'
          }`}
        >
          Library
        </button>
      </div>
      <div className="flex flex-col md:flex-row h-full">
        {activeTab === 'builder' && (
          <div className="flex-grow overflow-auto">
            <AssetBuilder />
          </div>
        )}
        {activeTab === 'library' && (
          <div className="flex-grow overflow-auto">
            <AssetLibrary />
          </div>
        )}
      </div>
    </div>
  );
}
