import React, { lazy, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSpinner, FaHeart } from 'react-icons/fa';
import AlertMessage from '../components/AlertMessage';
import Logo from '../components/Logo';
import { register } from '../server/users';

export default function Register() {
  const history = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [registerInfo, setRegisterInfo] = useState({
    username: null,
    email: null,
    password: null,
  });
  function handleEmailChange(event) {
    // console.log(event.target.value);
    setError(null);
    const newConfig = { ...registerInfo, email: event.target.value };
    setRegisterInfo(newConfig);
  }
  function handleUsernameChange(event) {
    // console.log(event.target.value);
    setError(null);
    const newConfig = { ...registerInfo, username: event.target.value };
    setRegisterInfo(newConfig);
  }

  function handlePwdChange(event) {
    setError(null);
    const newConfig = { ...registerInfo, password: event.target.value };
    setRegisterInfo(newConfig);
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    let body = {
      username: registerInfo.username,
      password: registerInfo.password,
      email: registerInfo.email,
    };
    // console.log({body})
    const response = await register(body);
    // console.log(response);

    if (response.error) {
      setError({ msg: response.error });
      setLoading(false);
      return;
    }
    let data = response.data;
    if (!data && !data.token) {
      setError({ msg: 'Unexpected Error' });
      setLoading(false);
    } else {
      // console.log(data);
      setTimeout(() => {
        history('/login');
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="w-full flex items-center justify-center m-2">
          <Logo />
        </div>
        <div className="w-full flex items-center justify-center bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 max-w-3xl mx-auto">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Register New Account
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="email"
                  required=""
                  value={registerInfo.email}
                  onChange={handleEmailChange}
                />
              </div>
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="username"
                  required=""
                  value={registerInfo.username}
                  onChange={handleUsernameChange}
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  value={registerInfo.password}
                  onChange={handlePwdChange}
                />
              </div>
              <button
                type="submit"
                onClick={handleClick}
                disabled={isLoading}
                className={`w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                } flex justify-center`}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  'Register'
                )}
              </button>
              {error && <AlertMessage message={error.msg} />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
