import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Profile from './Profile';
import Logo from './Logo';
import { Link, useNavigate } from 'react-router-dom';
import { FaCompass, FaHeart, FaPlusSquare, FaPaperPlane } from 'react-icons/fa';
import store from '../stores';
import { setUserId, setUsername, setToken } from '../stores/UserStore';

const Header = ({ onToggleSidebar }) => {
  const navigate = useNavigate();

  const username = useSelector((state) => state.user.username);
  const handleLogout = () => {
    // Clear the cached login information
    localStorage.removeItem('loggedInUser');
    store.dispatch(setUserId(null));
    store.dispatch(setUsername('Guest'));
    store.dispatch(setToken(null));
    // Redirect to login page
    console.log('Header: handleLogout');
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    // console.log('Header: username = ', username);
    if (username == 'Guest') {
      let cacheLogin = localStorage.getItem('loggedInUser');
      // console.log('Header: cacheLogin = ', cacheLogin);
      if (cacheLogin) {
        cacheLogin = JSON.parse(cacheLogin);
        store.dispatch(setUserId(cacheLogin.userId));
        store.dispatch(setUsername(cacheLogin.username));
        store.dispatch(setToken(cacheLogin.token));
      }
    }
  }, [username]);

  const HeaderObj = () => {
    return (
      <div className="bg-white py-2 px-4 flex items-center justify-between border-b-2 border-gray-200 fixed w-full top-0 z-50">
        <Logo />

        {/* <div className="hidden md:flex items-center space-x-6">
          <input
            type="text"
            placeholder="Search"
            className="border rounded p-1 text-sm bg-gray-100 text-gray-400"
          />

          <Link to="/new-post" title="Create New Post">
            <FaPlusSquare className="w-6 h-6 text-black hover:text-blue-400" />
          </Link>

          <Link to="/explore" title="Explore">
            <FaCompass className="w-6 h-6 text-black hover:text-blue-400" />
          </Link>

          <Link to="/activity" title="Activity">
            <FaHeart className="w-6 h-6 text-black hover:text-blue-400" />
          </Link>
        </div> */}

        <div className="flex items-center space-x-6">
          {/* <Link to="/direct-messages" title="Direct Messages">
            <FaPaperPlane className="w-6 h-6 text-black hover:text-blue-400 md:hidden" />
          </Link>

          <Link to="/activity" title="Activity">
            <FaHeart className="w-6 h-6 text-black hover:text-blue-400 md:hidden" />
          </Link> */}

          <button title="Login" onClick={handleLogout}>
            <div className="flex items-center space-x-2 border-2 hover:border-blue-400 p-2">
              <div class="flex flex-col items-start">
                <h2>{username}</h2>
                {username == 'Guest' && (
                  <span class="text-xs text-gray-400">Click to Sign In</span>
                )}
              </div>

              <img
                src="https://via.placeholder.com/150"
                alt="Profile"
                className="rounded-full w-6 h-6"
              />
            </div>
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <HeaderObj />
    </>
  );
};
export default Header;
