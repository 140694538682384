import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/inft`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const create = (body, token) =>
  axios.post(`${baseUrl}/create`, body, headers(token));

export const getOwned = (ownerId, token) =>
  axios.get(`${baseUrl}/owner/${ownerId}`, headers(token));

export const getOwnedAssets = (ownerId, token) =>
  axios.get(`${baseUrl}/owner/${ownerId}/assets`, headers(token));
