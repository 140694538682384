import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/ugc`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getUGC = (ugcId) => axios.get(`${baseUrl}?ugcId=${ugcId}`);

export const getiAsset = (iassetId) =>
  axios.get(`${baseUrl}/iasset?iassetId=${iassetId}`);

export const buyUGC = (body) => axios.post(`${baseUrl}/buy`, body);
