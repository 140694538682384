import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCheck } from "react-icons/fa";

const Header = () => {
  return (
    <div className="flex items-center justify-between bg-white p-4 border-b border-gray-300">
      <FaArrowLeft className="text-black text-2xl" />
      <p className="font-semibold text-lg text-black">New Post</p>
      <FaCheck className="text-blue-500 text-2xl" />
    </div>
  );
};

const ImagePreview = ({ imageSrc }) => {
  if (!imageSrc) {
    return (
      <div className="w-full bg-gray-200 flex items-center justify-center text-gray-500">
        No Image Selected
      </div>
    );
  }

  return (
    <div className="w-full h-0 pb-full overflow-hidden flex items-center justify-center overflow-hidden">
      <img
        src={imageSrc}
        alt="Selected Post"
        className="absolute top-0 left-0 w-auto h-auto max-w-full max-h-full object-scale-down m-auto"
      />
    </div>
  );
};

const MediaSelection = ({ onMediaSelected }) => {
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (onMediaSelected) {
        onMediaSelected(URL.createObjectURL(file));
      }
    }
  };

  return (
    <div className="flex items-center justify-center p-4 space-y-4">
      <label className="flex items-center px-4 py-2 bg-blue-500 text-white rounded cursor-pointer">
        <span className="mx-2">Select Image/Video</span>
        <input
          type="file"
          className="hidden"
          accept="image/*,video/*"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};

const Pill = ({ name, isAdded, onClick }) => (
  <div
    className={`flex items-center m-2 rounded-full p-2 cursor-pointer transition duration-200 ease-in-out transform hover:scale-105 ${
      isAdded ? "bg-blue-500 text-white" : "bg-gray-200 text-black"
    }`}
    onClick={onClick}
  >
    <p className="mr-2">{name}</p>
    {isAdded && <FaCheck />}
  </div>
);

const CreatePostPage = () => {
  const [assetData, setAssetData] = useState({});
  const [characteristicList, setCharacteristicList] = useState([
    "Walking",
    "Painting",
    "Coding",
    "Running",
    "Singing",
    "Dancing",
    "Jumping",
    "Sleeping",
    "Reading",
    "Writing",
    "Swimming",
    "Cooking",
    "Eating",
    "Flying",
    "Traveling",
    "Shopping",
    "Playing",
    "Hiking",
    "Climbing",
    "Drawing",
    "Designing",
  ]);
  const [selectedCharacteristic, setSelectedCharacteristic] = useState("");
  const [selectedScrollPosition, setSelectedScrollPosition] = useState(0);

  React.useEffect(() => {
    const container = document.getElementById("characteristic-container");
    if (container) {
      container.scrollLeft = selectedScrollPosition;
    }
  }, [selectedScrollPosition]);

  useEffect(() => {
    // console.log(assetData);
    // const inAssetData = characteristicList.filter(
    //   (characteristic) => assetData[characteristic]
    // );
    // console.log(inAssetData);
    // const notInAssetData = characteristicList.filter(
    //   (characteristic) => !assetData[characteristic]
    // );
    // setCharacteristicList([...inAssetData, ...notInAssetData]);
  }, [assetData]);

  useEffect(() => {
    // console.log({ characteristicList });
  }, [characteristicList]);

  const handleMediaSelected = (characteristic, media) => {
    setAssetData({ ...assetData, [characteristic]: media });
  };

  const handleCharacteristicSelected = (characteristic) => {
    // setSelectedCharacteristic(characteristic);
    let index = Object.keys(assetData).indexOf(characteristic);
    if (index === -1) {
      setAssetData({ ...assetData, [characteristic]: null });
      index = Object.keys(assetData).length;
    }
    const characteristicElements = document.getElementsByClassName(
      "characteristic-card"
    );

    setTimeout(() => {
      if (characteristicElements.length > 0) {
        const width = characteristicElements[0].offsetWidth;
        setSelectedScrollPosition(index * (width + 16));
      }
    }, 100);
  };

  const handleRemoveCharacteristic = (characteristic) => {
    const newAssetData = { ...assetData };
    delete newAssetData[characteristic];
    setAssetData(newAssetData);
  };

  return (
    <div className="flex flex-col h-full">
      <Header />
      <div className="p-2">
        <div className="mb-4">
          {/* Characteristic Selection */}
          <div className="flex overflow-x-auto scrollbar-hide space-x-4 p-2">
            {characteristicList.map((charName) => (
              <Pill
                key={charName}
                name={charName}
                isAdded={Object.keys(assetData).includes(charName)}
                onClick={() => {
                  handleCharacteristicSelected(charName);
                }}
              />
            ))}
          </div>
        </div>
        <div
          id="characteristic-container"
          className="flex overflow-x-scroll scrollbar-hide space-x-4 p-2 scrolling-touch"
        >
          {Object.entries(assetData).map(([characteristic, media]) => (
            <div
              key={characteristic}
              className="flex-none w-full w-60 md:w-96 characteristic-card"
            >
              <div className="border rounded p-2">
                <div className="flex justify-between">
                  <h2
                    className={
                      characteristic === selectedCharacteristic ? "active" : ""
                    }
                  >
                    {characteristic}
                  </h2>
                  <button
                    onClick={() => handleRemoveCharacteristic(characteristic)}
                  >
                    X
                  </button>
                </div>
                <div className="relative w-60 h-60 md:w-96 flex items-center justify-center">
                  <ImagePreview imageSrc={media} />
                </div>
                <MediaSelection
                  onMediaSelected={(media) =>
                    handleMediaSelected(characteristic, media)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreatePostPage;
