import React, { useState, useEffect, useRef, Component } from "react";
import { FaTrash, FaSpinner, FaExclamationTriangle, FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import FormUploader from "../components/FormUploader";
import { deleteCharacteristic } from "../server/characteristics";

import {
  getAllCharacteristicsByCreatorId,
  create,
} from "../server/characteristics";

export default function Characteristics() {
  const [isUploadPanelVisible, setUploadPanelVisible] = useState(true);

  const [characteristics, setCharacteristics] = useState([]);

  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    init();
    refresh();
  }, []);

  async function init() {
    //check if mobile, if so, hide upload panel
    if (window.innerWidth < 768) {
      setUploadPanelVisible(false);
    }
    await getCharacteristics();
  }

  async function refresh() {
    await getCharacteristics();
  }

  const getCharacteristics = async () => {
    try {
      const response = await getAllCharacteristicsByCreatorId(userId);
      //   console.log({ response });
      if (response.data) {
        let characteristics = response.data.data;
        characteristics = characteristics.map((c) => {
          return {
            id: c._id,
            name: c.name,
            type: c.type,
            previewImage: c.characteristicPreviewUrl,
          };
        });
        setCharacteristics(characteristics);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const createCharacteristic = async (characteristic) => {
    try {
      const response = await create(characteristic, token);
      console.log({ response });
      if (response.data) {
        let characteristic = response.data.data;
        return characteristic;
      }
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const submitForm = async (form) => {
    console.log("submitForm: ", form);
    let characteristic = {
      name: form[0].value,
      type: form[1].value,
      branded: false,
    };
    console.log({ characteristic });
    const response = await createCharacteristic(characteristic);
    return response;
  };

  function getInputForm() {
    let formArray = [];
    formArray.push({
      label: "Characteristic Name",
      type: "text",
      disabled: false,
      value: "",
    });
    formArray.push({
      label: "Characteristic Type",
      type: "options",
      options: [
        { value: "noun", label: "Noun" },
        { value: "verb", label: "Verb" },
        { value: "adjective", label: "Adjective" },
      ],
      value: "",
    });
    formArray.push({
      label: "Characteristic Image",
      type: "image",
      value: "",
    });
    formArray.push({
      label: "Branded",
      type: "checkbox",
      value: false,
    });
    return formArray;
  }

  const CharacteristicCard = ({ char }) => {
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState(false);
    const onDelete = async (char) => {
      setDeleting(true);
      try {
        const response = await deleteCharacteristic(char.id, token);
        if (response.status === 200) {
          refresh();
          setDeleting(false);
        } else {
          console.log({ response });
          setDeleting(false);
          setError(true);
        }
      } catch (error) {
        console.log({ error });
        setDeleting(false);
        setError(true);
      }
    };
    return (
      <div className="min-w-[250px] flex flex-col bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition">
        <div class="flex items-center justify-between">
          <div class="flex items-center mt-2 space-x-1">
            <span
              class={`inline-block px-2 py-1 text-xs font-bold rounded-full bg-gray-200 text-black mb-2`}
            >
              {char.type}
            </span>
          </div>
          <>
            {deleting ? (
              <FaSpinner className="text-xl md:text-xl animate-spin" />
            ) : error ? (
              <FaExclamationTriangle className="text-xl md:text-xl" />
            ) : (
              <button
                className="m-2 bg-red-500 text-white font-bold py-1 px-2 rounded focus:outline-none hover:bg-red-600"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(char);
                }}
              >
                <FaTrash className="text-xl md:text-xl" />
              </button>
            )}
          </>
        </div>
        <img
          className="w-full h-32 object-cover rounded-lg mb-4"
          src={char.previewImage}
          alt={`${char.name} Preview`}
        />
        <h3 className="text-lg font-semibold mb-2">{char.name}</h3>
      </div>
    );
  };

  const CreateCharPanel = () => {
    return (<div className="md:col-span-1">
            <FormUploader
              formTitle="Create Characteristic"
              onSubmit={submitForm}
              inputFormArray={getInputForm()}
            ></FormUploader>
          </div>)
  }

  const CollapsiblePanel = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="mb-4">
        <div className="flex justify-between p-4 bg-gray-100 rounded-t-lg">
          <span className="text-lg font-semibold">Create Characteristic</span>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FaChevronDown className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
          </button>
        </div>
        {isOpen && (
          <div className="p-4 bg-white border border-t-0 rounded-b-lg">
            <CreateCharPanel />
          </div>
        )}
      </div>
    );
  }

  return (
    <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 h-full">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
      <CollapsiblePanel />
      <div className="max-h-[500px] overflow-y-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
            {characteristics.map((char) => (
              <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition">
                <CharacteristicCard char={char} />
              </div>
            ))}
        </div>
      </div>
      </div>
    </div>
  );
}
