import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaPlus, FaWallet, FaCogs, FaUserCircle } from "react-icons/fa";

import { uploadRawFile } from "../server/rawFiles";

const FormUploader = ({ formTitle, inputFormArray, onSubmit }) => {
  const [localForm, setLocalForm] = useState(inputFormArray);
  // The state for the files
  const [files, setFiles] = useState([]);

  // The state for the upload progress
  const [progress, setProgress] = useState({});

  const [uploading, setUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    // console.log(files);
    if (files.length > 0) {
      setTimeout(() => {
        files.forEach((file) => {
          if (!file.s3Location) {
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: 10,
            }));
            upload(file);
          }
        });
      }, 500);
    }
  }, [files]);

  const upload = async (file) => {
    try {
      const response = await uploadRawFile(file, token);
      console.log({ response });
      if (response.data) {
        let file = response.data.data;
        file.s3Location = file.rawFileUrl;
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
        //update the file in the files array
        setFiles((prevFiles) => {
          let newFiles = [...prevFiles];
          newFiles.forEach((f) => {
            if (f.name === file.name) {
              f.s3Location = file.rawFileUrl;
              f.rawFileId = file._id;
            }
          });
          return newFiles;
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    setErrorMsg();
    setUploading(true);
    try {
      const result = await onSubmit(localForm);
      console.log({ result });
      if (result.status !== 200) {
        setErrorMsg(result.message);
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
      // Handle the error appropriately, if necessary
    }
    setUploading(false);
  };

  const getSubmitText = () => {
    if (localForm) {
      //Find by type "submit"
      let submitField = localForm.find((field) => field.type === "submit");
      if (submitField) {
        return submitField.label;
      }
    }
    return "Submit";
  };

  const TextField = ({ field, index }) => {
    return (
      <div key={index} className="flex items-center">
        <label className="block text-gray-600 mr-4 ml-4 font-bold">
          {field.label}
        </label>
        <input
          className="border border-gray-300 p-2 flex-1 m-2 text-right"
          type="text"
          name={field.name}
          value={field.value}
          autoComplete="off"
          disabled={field.disabled}
          onChange={(e) => {
            setErrorMsg();
            const newForm = [...localForm];
            newForm[index].value = e.target.value;
            setLocalForm(newForm);
          }}
          onClick={(e) => {
            setErrorMsg();
          }}
        />
      </div>
    );
  };

  const OptionsField = ({ field, index }) => {
    return (
      <div key={index} className="flex items-center justify-between">
        <label
          className="block text-gray-600 mr-4 ml-4 font-bold"
          htmlFor={field.label}
        >
          {field.label}
        </label>
        <select
          className="border border-gray-300 p-2 flex-1 m-2 text-right"
          name={field.name}
          value={field.value}
          onChange={(e) => {
            const newForm = [...localForm];
            newForm[index].value = e.target.value;
            setLocalForm(newForm);
          }}
        >
          {field.options.map((option, optionIndex) => (
            <option key={optionIndex} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-lg font-bold text-gray-800">{formTitle}</h1>
      <>
        {localForm.map((form, index) => {
          switch (form.type) {
            case "text":
              return TextField({ field: form, index });
            case "options":
              return OptionsField({ field: form, index });
            default:
              break;
          }
        })}
      </>
      {!uploading ? (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg mt-4"
          onClick={handleSubmit}
        >
          {getSubmitText()}
        </button>
      ) : (
        <div className="p-2 rounded-md mt-2">
          <p className="text-md text-blue-500">Creating ...</p>
        </div>
      )}
      {errorMsg && (
        <div className="flex justify-center pt-2">
          <p className="text-md text-red-500">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default FormUploader;
