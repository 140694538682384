import { useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  return (
    <>
      <button class="flex ml-2 md:mr-24" onClick={handleClick}>
        <img
          type="submit"
          src="/mm_logo.png"
          class="h-14 mr-3"
          alt="FlowBite Logo"
        />
        <span class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap">
          MeshMill
        </span>
      </button>
    </>
  );
};
export default Logo;
