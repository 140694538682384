import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import store from '../stores';
import { setUsername } from '../stores/UserStore';

const Profile = () => {
  const history = useNavigate();
  const loggedUsername = useAppSelector((state) => state.user.username);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let cachedUser = localStorage.getItem('loggedInUser');
    if (cachedUser) {
      cachedUser = JSON.parse(cachedUser);
      // console.log(cachedUser);
      store.dispatch(setUsername(cachedUser.username));
    }
  };

  useEffect(() => {
    console.log('Profile: loggedUsername = ', loggedUsername);
  }, [loggedUsername]);

  const onLogout = () => {
    console.log('Profile: onLogout');
    setIsOpen(false);
    store.dispatch(setUsername(null));
  };

  const onLogin = () => {
    console.log('Profile: onLogin');
    setIsOpen(false);
    history.push('/login');
  };

  return (
    <>
      <div class="flex items-center ml-3">
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            class="flex items-center justify-between bg-gray-800 rounded-full p-2 text-white focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          >
            <img
              class="w-8 h-8 rounded-full mr-3"
              src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              alt="user photo"
            />
            {loggedUsername ? (
              <span class="font-medium text-sm">{loggedUsername}</span>
            ) : (
              <div class="flex flex-col items-start">
                <span class="font-medium text-sm">Guest</span>
                <span class="text-xs text-gray-400">Click to Sign In</span>
              </div>
            )}
          </button>
          {isOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
              {loggedUsername ? (
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                  onClick={onLogout}
                >
                  Logout
                </button>
              ) : (
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                  onClick={onLogin}
                >
                  Go to Login Page
                </button>
              )}
            </div>
          )}
        </div>
        {/* <div
          className={`z-50 ${
            isOpen ? "" : "hidden"
          } my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
          id="dropdown-user`}
        >
          <div class="px-4 py-3" role="none">
            <p class="text-sm text-gray-900 dark:text-white" role="none">
              Neil Sims
            </p>
            <p
              class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
              role="none"
            >
              neil.sims@flowbite.com
            </p>
          </div>
          <ul class="py-1" role="none">
            <li>
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Settings
              </a>
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Earnings
              </a>
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Sign out
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default Profile;
