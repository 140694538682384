import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/rawFile`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const uploadRawFile = (file, token) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${baseUrl}/upload`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteRawFile = (rawFileId, token) =>
  axios.delete(`${baseUrl}/delete/${rawFileId}`, headers(token));

export const getRawFile = (rawFileId) =>
  axios.get(`${baseUrl}/get/${rawFileId}`);

export const getAllRawFilesByCreatorId = (creatorId) =>
  axios.get(`${baseUrl}/creator/${creatorId}`);

export const getAllRawFiles = () => axios.get(`${baseUrl}/all`);
