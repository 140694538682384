import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAllCharacteristics } from '../server/characteristics';
import { getAllComponentsByCreatorId } from '../server/components';
import { create } from '../server/assets';

import { FaCheck, FaTimes, FaPlus } from 'react-icons/fa';

function AssetBuilder() {
  const [allChars, setAllChars] = useState([]);
  const [allComps, setAllComps] = useState([]);

  const [attachedCharacteristics, setAttachedCharacteristics] = useState([]);
  const [attachedComponents, setAttachedComponents] = useState([]);
  const [selectedCharacteristic, setSelectedCharacteristic] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [assetName, setAssetName] = useState('');

  const [creating, setCreating] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (userId && userId != null) {
      init();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedCharacteristic) {
      console.log('Selected characteristic:', selectedCharacteristic);
    }
  }, [selectedCharacteristic]);

  const createAssetOnServer = async (assetBody) => {
    try {
      const response = await create(assetBody, token);
      return response;
    } catch (error) {
      return { status: 400, message: 'Error creating asset' };
    }
  };

  async function init() {
    await loadChars();
    await loadOwnedComponents();
  }

  const loadChars = async () => {
    try {
      const response = await getAllCharacteristics();
      //   console.log({ response });
      if (response.data) {
        let characteristics = response.data.data;
        characteristics = characteristics.map((c) => {
          return {
            name: c.name,
            id: c._id,
          };
        });
        setAllChars(characteristics);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const loadOwnedComponents = async () => {
    try {
      if (!userId) return;
      const response = await getAllComponentsByCreatorId(userId);
      //   console.log({ response });
      if (response.data) {
        let components = response.data.data;
        components = components.map((comp) => {
          return {
            id: comp._id,
            name: comp.name,
            type: comp.type,
            previewImage: comp.rawFileUrl,
          };
        });
        // console.log('found owned components: ', components.length);
        setAllComps(components);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleAttachCharacteristic = (characteristic) => {
    if (!attachedCharacteristics.some((c) => c.id === characteristic.id)) {
      setAttachedCharacteristics([...attachedCharacteristics, characteristic]);
    }
  };

  const handleSelectCharacteristic = (characteristic) => {
    setSelectedCharacteristic(characteristic);
    setAttachedComponents(characteristic.components || []);
  };

  const handleAttachCompToChar = (component) => {
    // console.log('Attaching component to characteristic:', component);
    setSelectedComponent(component);
    setAttachedCharacteristics((prevChars) => {
      let newChars = [...prevChars];
      let charIndex = newChars.findIndex(
        (c) => c.id === selectedCharacteristic.id,
      );
      if (charIndex !== -1) {
        let newChar = { ...newChars[charIndex] };
        if (!newChar.components) {
          newChar.components = [];
        }
        if (!newChar.components.some((c) => c.id === component.id)) {
          newChar.components.push(component);
        }
        newChars[charIndex] = newChar;
      }
      return newChars;
    });

    if (!attachedComponents.some((c) => c.id === component.id)) {
      setAttachedComponents([...attachedComponents, component]);
    }
  };

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleRemoveCharacteristic = (charId) => {
    setAttachedCharacteristics((prev) =>
      prev.filter((char) => char.id !== charId),
    );
    if (selectedCharacteristic && selectedCharacteristic.id === charId) {
      setSelectedCharacteristic(null);
    }
  };

  const handleRemoveComponent = (compId) => {
    setAttachedComponents((prev) => prev.filter((comp) => comp.id !== compId));
    setAttachedCharacteristics((prevChars) => {
      let newChars = [...prevChars];
      let charIndex = newChars.findIndex(
        (c) => c.id === selectedCharacteristic.id,
      );
      if (charIndex !== -1) {
        let newChar = { ...newChars[charIndex] };
        if (newChar.components) {
          newChar.components = newChar.components.filter(
            (comp) => comp.id !== compId,
          );
        }
        newChars[charIndex] = newChar;
      }
      return newChars;
    });
  };

  const createAsset = async () => {
    setCreating(true);
    setSuccessMsg('');
    setErrorMsg('');
    if (!assetName || assetName === '') {
      setErrorMsg('Please enter a name for your asset');
      setCreating(false);
      return;
    }
    let asset = {};
    asset.name = assetName;
    asset.desc = 'Test desc';
    asset.content = [];
    attachedCharacteristics.forEach((char) => {
      let newChar = { id: char.id };
      if (char.components) {
        newChar.components = char.components.map((comp) => {
          return comp.id;
        });
      }
      asset.content.push({ characteristic: newChar });
    });
    asset.royalty = '2.5';
    asset.price = 100;
    const response = await createAssetOnServer(asset);
    if (response.status === 200) {
      //   refresh();
      setCreating(false);
      setSuccessMsg('Asset created successfully');
      //change route to asset library
      setTimeout(() => {
        navigate(`/assets?name=${encodeURIComponent(assetName)}`);
      }, 1000);
    } else {
      console.log(response);
      setErrorMsg('Error creating asset');
      setCreating(false);
    }
  };

  const selectedCharPanel = () => {
    return (
      <div className="bg-white rounded-lg shadow-md mb-4 p-6">
        <h2 className="text-2xl font-semibold mb-4">
          {selectedCharacteristic.name}
        </h2>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-3">Add Components</h3>
            <div className="h-64 overflow-y-auto border border-gray-200 rounded-lg">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 p-2">
                {allComps.map((comp) => {
                  const isAttached = attachedComponents.some(
                    (c) => c.id === comp.id,
                  );
                  return (
                    <button
                      key={comp.id}
                      onClick={() => handleAttachCompToChar(comp)}
                      className={`relative p-2 rounded-lg border transition-all duration-200 ${
                        isAttached
                          ? 'border-green-500 bg-green-50'
                          : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
                      }`}
                    >
                      <img
                        src={comp.previewImage}
                        alt={comp.name}
                        className="w-full h-16 object-cover rounded mb-1"
                      />
                      <span className="text-xs font-medium truncate block">
                        {comp.name}
                      </span>
                      {isAttached ? (
                        <div className="absolute top-1 right-1 bg-green-500 rounded-full p-0.5">
                          <FaCheck className="w-3 h-3 text-white" />
                        </div>
                      ) : (
                        <div className="absolute top-1 right-1 bg-blue-500 rounded-full p-0.5">
                          <FaPlus className="w-3 h-3 text-white" />
                        </div>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
            {allComps.length === 0 && (
              <p className="text-gray-500 italic mt-2">
                No Components available
              </p>
            )}
          </div>

          <div>
            <h3 className="text-lg font-medium mb-3">Attached Components</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {attachedComponents.map((comp) => (
                <div
                  key={comp.id}
                  className="relative p-2 rounded-lg border-2 border-green-500 bg-green-50"
                >
                  <img
                    src={comp.previewImage}
                    alt={comp.name}
                    className="w-full h-24 object-cover rounded mb-2"
                  />
                  <span className="text-sm font-medium truncate block">
                    {comp.name}
                  </span>
                  <button
                    onClick={() => handleRemoveComponent(comp.id)}
                    className="absolute top-1 right-1 bg-red-500 rounded-full p-1"
                  >
                    <FaTimes className="w-3 h-3 text-white" />
                  </button>
                </div>
              ))}
              {attachedComponents.length === 0 && (
                <p className="text-gray-500 italic">No components attached</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h1 className="text-lg font-semibold mb-4">Build Your Asset</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
        <div className="col-4">
          <div className="mb-4">
            <label htmlFor="assetName" className="block font-medium">
              Name Your Asset:
            </label>
            <input
              type="text"
              id="assetName"
              value={assetName}
              onChange={handleAssetNameChange}
              className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter asset name"
            />
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Characteristics</h2>
            <div className="space-y-2">
              {allChars.map((char) => (
                <button
                  key={char.id}
                  onClick={() => handleAttachCharacteristic(char)}
                  className={`w-full text-left px-4 py-2 rounded-md transition-colors flex justify-between items-center ${
                    attachedCharacteristics.some((c) => c.id === char.id)
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  <span>{char.name}</span>
                  {attachedCharacteristics.some((c) => c.id === char.id) ? (
                    <FaCheck className="inline-block ml-2 w-4 h-4" />
                  ) : (
                    <FaPlus className="inline-block ml-2 w-4 h-4" />
                  )}
                </button>
              ))}
              {allChars.length === 0 && <p>No characteristics loaded</p>}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">
              Modify Content for Attached Characteristic:
            </h2>
            <div className="space-y-2">
              {attachedCharacteristics.map((char) => (
                <div key={char.id} className="flex items-center">
                  <button
                    onClick={() => handleSelectCharacteristic(char)}
                    className={`flex-grow text-left px-4 py-2 rounded-md transition-colors ${
                      selectedCharacteristic &&
                      selectedCharacteristic.id === char.id
                        ? 'bg-blue-500 text-white'
                        : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                    }`}
                  >
                    <span>{char.name}</span>
                  </button>
                  <button
                    onClick={() => handleRemoveCharacteristic(char.id)}
                    className="ml-2 bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition-colors"
                  >
                    <FaTimes className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-3">
          {selectedCharacteristic &&
            selectedCharPanel(selectedCharacteristic.id)}
        </div>
      </div>

      {!creating && (
        <button
          onClick={createAsset}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition-colors"
        >
          Create Asset
        </button>
      )}
      {creating && <p>Creating asset...</p>}
      {errorMsg && <p className="text-red-500">{errorMsg}</p>}
      {successMsg && <p className="text-green-500">{successMsg}</p>}
    </div>
  );
}

export default AssetBuilder;
