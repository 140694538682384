import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/characteristic`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const create = (characteristicBody, token) =>
  axios.post(`${baseUrl}/create`, characteristicBody, headers(token));

export const update = (characteristicId, component, token) =>
  axios.put(`${baseUrl}/update/${characteristicId}`, component, headers(token));

export const deleteCharacteristic = (characteristicId, token) =>
  axios.delete(`${baseUrl}/delete/${characteristicId}`, headers(token));

export const getCharacteristic = (characteristicId, token) =>
  axios.get(`${baseUrl}/get/${characteristicId}`, headers(token));

export const getAllCharacteristicsByCreatorId = (creatorId) =>
  axios.get(`${baseUrl}/creator/${creatorId}`);

export const getAllCharacteristics = () => axios.get(`${baseUrl}/all`);
