import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { uploadRawFile } from '../server/rawFiles';
import { processRawFile, processAndUploadRawFile } from '../server/components';

const FileUploader = ({ onProcessed }) => {
  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);
  // The state for the files
  const [files, setFiles] = useState([]);

  // The state for the upload progress
  const [progress, setProgress] = useState({});

  const [uploading, setUploading] = useState(false);
  // The state for the submit button
  const [submit, setSubmit] = useState(false);

  // The state for the result object
  const [result, setResult] = useState({});

  // The dropzone hook for the file input
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.webp', '.jpg'],
      'application/octet-stream': ['.fbx'],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        if (!file.preview) {
          file.preview = getPreviewImage(file);
        }
        let mbSize = (file.size / 1000000).toFixed(2);
        file.filesize = mbSize;
      });
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    // console.log(files);
    if (files.length > 0) {
      setTimeout(() => {
        files.forEach(async (file) => {
          if (!file.s3Location) {
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: 100,
            }));
            // let result = await upload(file);
            // if (!result) {
            //   console.log('upload failed');
            //   setProgress((prevProgress) => ({
            //     ...prevProgress,
            //     [file.name]: -1,
            //   }));
            // }
          }
        });
      }, 500);
    }
  }, [files]);

  // The useEffect hook for the submit button
  useEffect(() => {
    // If the submit button is clicked
    if (submit) {
      // Log the result object to the console
      //   console.log(result);
    }
  }, [submit, result]);

  const upload = async (file) => {
    try {
      const response = await uploadRawFile(file, token);
      if (response.data) {
        let file = response.data.data;
        file.s3Location = file.rawFileUrl;
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
        //update the file in the files array
        setFiles((prevFiles) => {
          let newFiles = [...prevFiles];
          newFiles.forEach((f) => {
            if (f.name === file.name) {
              f.s3Location = file.rawFileUrl;
              f.rawFileId = file._id;
            }
          });
          return newFiles;
        });
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const processFile = async (file) => {
    try {
      console.log('processing file: ', file);
      const response = await processAndUploadRawFile(file, token);
      if (response.data) {
        console.log(response.data);
        return response.data.data;
      }
    } catch (error) {
      console.log({ error });
      return error;
    }
  };
  // The function to handle the submit button click
  const handleSubmit = async () => {
    setUploading(true);
    //process each uploaded file
    const promises = files.map(async (file) => {
      let processedComponent = await processFile(file);
      return { component: processedComponent, file: file };
    });
    const updatedComps = await Promise.all(promises);
    // console.log({ updatedComps });
    updatedComps.forEach(async (obj) => {
      let file = obj.file;
      let component = obj.component;
      if (component instanceof Error) {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: -1,
        }));
      } else {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: -2,
        }));
        onProcessed(updatedComps);
      }
    });
    setUploading(false);
    // setTimeout(() => {
    //   setFiles([]);
    // }, 5000);
  };

  const getPreviewImage = (file) => {
    //get file extension
    let fileExtension = file.name.split('.')[1];

    if (
      fileExtension === 'png' ||
      fileExtension === 'jpeg' ||
      fileExtension === 'jpg' ||
      fileExtension === 'jfif'
    ) {
      return URL.createObjectURL(file);
    } else if (fileExtension === 'fbx') {
      return 'https://via.placeholder.com/80';
    }
  };

  // The function to render the thumbnails of the files
  const renderThumbnails = () => {
    return files.map((file) => (
      <div
        key={file.name}
        className="flex flex-col sm:flex-row items-center p-2 border-b w-100"
      >
        <img
          src={file.preview ? file.preview : 'https://via.placeholder.com/80'}
          alt={file.name}
          className="w-8 h-8 sm:w-12 sm:h-12 object-cover mb-2 sm:mb-0 sm:mr-4"
        />
        <div className="mb-2 sm:mb-0 px-2 flex-grow">
          <div className="relative sm:w-16 md:w-32 lg:w-48 lg:truncate">
            <p className="text-xs sm:text-sm font-medium text-gray-800 lg:truncate w-[100px]">
              {file.name}
            </p>
          </div>
          <p className="text-xxs sm:text-xs text-gray-600">
            {file.filesize} MB
          </p>
        </div>

        {progress[file.name] &&
          progress[file.name] != -1 &&
          progress[file.name] != -2 && (
            <div className="ml-2 flex justify-center">
              <div className="w-16 h-16 relative">
                <CircularProgressbar
                  value={progress[file.name] || 0}
                  text={`${progress[file.name] || 0}%`}
                  className="absolute top-0 left-0 w-full h-full"
                />
              </div>
            </div>
          )}
        {progress[file.name] && progress[file.name] == -1 && (
          <div className="w-full ml-2 flex justify-center">
            <FaExclamationTriangle className="w-5 h-5 text-red-500" />
          </div>
        )}
        {progress[file.name] && progress[file.name] == -2 && (
          <div className="w-full ml-2 flex justify-center">
            <FaCheckCircle className="w-5 h-5 text-green-500" />
          </div>
        )}
      </div>
    ));
  };

  function readFile(file) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader();

      // Register event listeners
      reader.addEventListener('loadend', (e) => resolve(e.target.result));
      reader.addEventListener('error', reject);

      // Read file
      reader.readAsArrayBuffer(file);
    });
  }

  async function getAsByteArray(file) {
    return new Uint8Array(await readFile(file));
  }

  return (
    <div className="flex flex-col">
      {/* <h1 className="text-lg font-bold text-gray-800">Upload Files</h1> */}
      <div
        {...getRootProps({
          className:
            'flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg mt-4 cursor-pointer',
        })}
      >
        <input {...getInputProps()} />
        <p className="text-md text-gray-600">
          Drag and drop some files here, or click to select files
        </p>
        <div className="border-2 border-dashed border-green-500 p-2 rounded-md mt-2">
          <p className="text-sm text-green-500">
            Note: Only .fbx, .jpg, and .png files are currently supported.
          </p>
        </div>
      </div>
      <div className="mt-4 overflow-y-auto max-h-64">{renderThumbnails()}</div>
      {!uploading ? (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg mt-4"
          onClick={handleSubmit}
        >
          Process Components
        </button>
      ) : (
        <div className="p-2 rounded-md mt-2">
          <p className="text-md text-blue-500">Uploading ...</p>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
