import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/asset`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const create = (assetBody, token) =>
  axios.post(`${baseUrl}/create`, assetBody, headers(token));

export const update = (assetId, assetBody, token) =>
  axios.put(`${baseUrl}/update/${assetId}`, assetBody, headers(token));

export const deleteAsset = (assetId, token) =>
  axios.delete(`${baseUrl}/delete/${assetId}`, headers(token));

export const publishAsset = (assetId, token) =>
  axios.put(`${baseUrl}/publish/${assetId}`, {}, headers(token));

export const unpublishAsset = (assetId, token) =>
  axios.put(`${baseUrl}/unpublish/${assetId}`, {}, headers(token));

export const uploadPreview = (assetId, base64String, token) => {
  const BASE64_MARKER = ';base64,';
  const mimeType = base64String.split(BASE64_MARKER)[0].split(':')[1];
  const byteCharacters = atob(base64String.split(BASE64_MARKER)[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const formData = new FormData();
  formData.append('file', blob, 't.jpg');

  return axios.put(`${baseUrl}/upload/preview/${assetId}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getAsset = (assetId, token) =>
  axios.get(`${baseUrl}/get/${assetId}`, headers(token));

export const getAssetMario = (assetId, token) =>
  axios.get(`${baseUrl}/get/mario/${assetId}`, headers(token));

export const getAllAssetsByCreatorId = (creatorId) =>
  axios.get(`${baseUrl}/creator/${creatorId}`);

export const getAllPublishedAssets = () => axios.get(`${baseUrl}/published`);
