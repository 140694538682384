import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createPaymentIntent } from '../utils/api';
import { buyUGC } from '../server/ugc';

function CheckoutPage() {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const ugc = location.state
    ? location.state.ugc
    : { title: '', price: '', royalty: '' };
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    console.log(ugc);
  }, [location.state.ugc]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentProcessing(true);

    try {
      const totalAmount = ugc.price * 100; // Convert to cents
      console.log(totalAmount);
      const { clientSecret } = await createPaymentIntent(totalAmount);

      const cardElement = elements.getElement(CardElement);

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        },
      );

      if (error) {
        console.error('Payment failed:', error);
        setErrorMessage(error.message);
        setPaymentProcessing(false);
      } else {
        setErrorMessage('');
        console.log('Payment succeeded:', paymentIntent);
        setPaymentSuccess(true);
        setPaymentProcessing(false);
        // navigate(`/ugc/${ugc.id}`);
        try {
          let body = {
            ugcId: ugc.id,
            newOwnerId: ugc.newOwnerId,
            newOwnerName: ugc.newOwnerName,
            type: ugc.type,
            name: ugc.title,
            price: ugc.price,
            royalty: ugc.royalty,
          };
          const response = await buyUGC(body);
          console.log(response);
          if (ugc.type == 'iasset') {
            let ugcData = response.data.data;
            navigate(`/ugc/${ugcData._id}`);
          } else {
            navigate(`/ugc/${ugc.id}`);
          }
        } catch (error) {
          console.error('Error during payment processing:', error);
          setErrorMessage('An unexpected error occurred. Please try again.');
          setPaymentProcessing(false);
        }
      }
    } catch (error) {
      console.error('Error during payment processing:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
      setPaymentProcessing(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg mt-8">
      <h2 className="text-2xl font-bold mb-4">Checkout</h2>
      <div className="mb-4">
        <h2 className="font-semibold">Item Details:</h2>
        <p>Title: {ugc.title}</p>
        <p>Price: ${ugc.price}</p>
        <p>Royalty: {ugc.royalty}%</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="border p-2 rounded">
          <CardElement className="p-2" />
        </div>
        <button
          type="submit"
          disabled={!stripe || paymentProcessing}
          className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
        >
          {paymentProcessing ? 'Processing...' : 'Pay Now'}
        </button>
      </form>
      {paymentSuccess && (
        <p className="mt-4 text-green-600">Payment succeeded!</p>
      )}
      {errorMessage && <p className="mt-4 text-red-600">{errorMessage}</p>}
    </div>
  );
}

export default CheckoutPage;
