import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getiAsset } from '../server/ugc';
import { useAppSelector } from '../hooks';

function IAssetPage() {
  const { iassetId } = useParams();
  const [iasset, seIasset] = useState(null);
  const [owned, setOwned] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const navigate = useNavigate();

  const loggedUserId = useAppSelector((state) => state.user.userId);
  const loggedUserName = useAppSelector((state) => state.user.username);

  useEffect(() => {
    // console.log(iassetId);
  }, []);

  useEffect(() => {
    console.log('IAssetPage: loggedUserId = ', loggedUserId);
    if (loggedUserId) {
      init();
    }
  }, [loggedUserId]);

  async function init() {
    if (!iassetId) {
      console.log('iasset ID not found');
      return;
    }
    try {
      if (iassetId) {
        const response = await getiAsset(iassetId);
        let iassetFound = response.data.data;
        console.log(iassetFound);
        seIasset({
          id: iassetFound._id,
          artistInfo: {
            username: iassetFound.ownerName,
            profilePic: 'https://via.placeholder.com/300',
          },
          image: iassetFound.assetPreviewUrl,
          title: iassetFound.name,
          description: 'Placeholder Description',
          price: iassetFound.price,
          royalty: iassetFound.royalty,
          newOwnerId: loggedUserId,
          newOwnerName: loggedUserName,
          type: 'iasset',
        });
        // if (loggedUserId == iassetFound.ownerId) {
        //   setOwned(true);
        // }
        if (loggedUserId == iassetFound.creatorId) {
          setIsCreator(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const ArtistInfo = ({ artistInfo }) => (
    <div className="flex items-center mb-2 w-full justify-between">
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12"
          src={artistInfo.profilePic}
          alt={artistInfo.username}
        />
        <h2 className="text-lg md:text-xl font-bold ml-3">
          {artistInfo.username}
        </h2>
      </div>
      <a
        href={`/profile/${artistInfo.username}`}
        className="text-sm font-medium text-blue-500"
      >
        Back to Profile
      </a>
    </div>
  );

  const IAssetInfo = ({ title, description, price, royalty }) => (
    <div className="w-full md:w-2/3 mt-2">
      <h3 className="text-lg md:text-xl font-bold">{title}</h3>
      <p className="text-sm md:text-base">{description}</p>
      {price && (
        <div className="flex items-center justify-between">
          <span className="text-sm md:text-base font-medium">
            Price: ${price}
          </span>
          {!owned && !isCreator && (
            <button
              className="px-3 py-1 md:px-5 md:py-2 border border-gray-300 rounded-md text-sm font-bold"
              onClick={handleBuyNow}
            >
              Buy Now
            </button>
          )}
          {owned && (
            <span className="text-sm md:text-base font-medium text-green-500">
              Owned
            </span>
          )}
          {isCreator && (
            <span className="text-sm md:text-base font-medium text-orange-500">
              Creator
            </span>
          )}
        </div>
      )}
      {royalty && (
        <div className="mb-2">
          <span className="text-sm md:text-base font-medium">
            Royalty for Resell: {royalty}%
          </span>
        </div>
      )}
    </div>
  );

  const handleBuyNow = () => {
    navigate('/checkout', { state: { ugc: iasset } });
  };

  return (
    <div className="flex flex-col items-center p-4 md:p-2 md:mb-14 mt-4">
      {iasset && ArtistInfo({ artistInfo: iasset.artistInfo })}
      {iasset && (
        <div
          className="w-full md:w-2/3 rounded-lg overflow-hidden"
          style={{ maxHeight: '60vh' }}
        >
          <img
            className="object-cover w-full h-full"
            src={iasset.image}
            alt={iasset.title}
          />
        </div>
      )}
      {iasset &&
        IAssetInfo({
          title: iasset.title,
          description: iasset.description,
          price: iasset.price,
          royalty: iasset.royalty,
        })}
    </div>
  );
}

export default IAssetPage;
