import React from 'react';
import { FaHome, FaWallet, FaCogs, FaUserCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

function BottomNavigation() {
  const location = useLocation();
  const currentPath = location.pathname;

  const isSelected = (path) =>
    currentPath === path
      ? 'text-blue-600 dark:text-blue-500'
      : 'text-gray-500 dark:text-gray-400';

  const LinkBtn = ({ path, icon: Icon, label }) => {
    return (
      <Link
        to={path}
        className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
      >
        <Icon className={`w-5 h-5 mb-2 ${isSelected(path)}`} />
        <span className={`text-sm ${isSelected(path)}`}>{label}</span>
      </Link>
    );
  };

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full grid-cols-4 mx-auto font-medium md:max-w-lg">
        <LinkBtn
          path="/home"
          icon={FaHome}
          label="Home"
          className="text-xs md:text-base"
        />
        <LinkBtn
          path="/components"
          icon={FaWallet}
          label="Component"
          className="text-xs md:text-base"
        />
        <LinkBtn
          path="/assets"
          icon={FaUserCircle}
          label="Assets"
          className="text-xs md:text-base"
        />
        <LinkBtn
          path="/characteristics"
          icon={FaCogs}
          label="Characteristics"
          className="text-xs md:text-base"
        />
      </div>
    </div>
  );
}

export default BottomNavigation;
