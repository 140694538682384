import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaEllipsisH,
  FaRegHeart,
  FaHeart,
  FaRegComment,
  FaPaperPlane,
  FaRegBookmark,
  FaBookmark,
} from 'react-icons/fa';

import InteractiveIcon from '../components/InteractiveIcon';
import Swiper from '../components/Swiper';

import { useSelector } from 'react-redux';
import { getAllPublishedAssets } from '../server/assets';
import { getOwnedAssets } from '../server/inft';
import { create } from '../server/inft';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);
  const [publishedAssets, setPublishedAssets] = useState([]);
  const [ownedAssets, setOwnedAssets] = useState([]);

  useEffect(() => {
    if (userId) {
      init();
      refresh();
    }
  }, [userId]);

  useEffect(() => {
    if (ownedAssets.length > 0) {
      updatePublishedAssets();
    }
  }, [ownedAssets]);

  async function init() {
    await getPublishedAssets();
    await initOwned();
  }

  async function refresh() {}

  async function getPublishedAssets() {
    try {
      const response = await getAllPublishedAssets();
      let publishedAssets = response.data.data;
      console.log(publishedAssets);
      publishedAssets = publishedAssets.map((asset) => {
        let isCreator = asset.creatorId == userId;
        return {
          id: asset._id,
          ugcId: asset.ugcId,
          name: asset.name,
          description: asset.description,
          creatorId: asset.creatorId,
          creatorName: asset.ownerName,
          price: asset.price,
          images: [asset.assetPreviewUrl],
          isCreator: isCreator,
          isOwner: false,
          likes: 0,
        };
      });
      setPublishedAssets(publishedAssets);
    } catch (error) {
      console.log(error);
    }
  }

  function updatePublishedAssets() {
    let newPublishedAssets = publishedAssets.map((asset) => {
      let isOwner = ownedAssets.includes(asset.id);
      return {
        ...asset,
        isOwner: isOwner,
      };
    });
    setPublishedAssets(newPublishedAssets);
  }

  async function initOwned() {
    try {
      const response = await getOwnedAssets(userId);
      let ownedAssets = response.data.data;
      ownedAssets = ownedAssets.map((asset) => {
        return asset._id;
      });
      // console.log({ ownedAssets });
      setOwnedAssets(ownedAssets);
    } catch (error) {
      console.log(error);
    }
  }
  // Dummy data
  //   const posts = [
  //     {
  //       id: 1,
  //       user: "john_doe",
  //       images: [
  //         "https://via.placeholder.com/640",
  //         "https://via.placeholder.com/150",
  //         "https://via.placeholder.com/640",
  //         "https://via.placeholder.com/640",
  //         "https://via.placeholder.com/640",
  //       ],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "This is a beautiful view!",
  //       likes: 100,
  //     },
  //     {
  //       id: 2,
  //       user: "jane_doe",
  //       images: ["https://via.placeholder.com/640"],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "Loving this!",
  //       likes: 100,
  //     },
  //     {
  //       id: 2,
  //       user: "jane_doe",
  //       images: ["https://via.placeholder.com/640"],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "Loving this!",
  //       likes: 100,
  //     },
  //     {
  //       id: 2,
  //       user: "jane_doe",
  //       images: [
  //         "https://via.placeholder.com/640",
  //         "https://via.placeholder.com/640",
  //         "https://via.placeholder.com/640",
  //       ],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "Loving this!",
  //       likes: 100,
  //     },
  //     {
  //       id: 2,
  //       user: "jane_doe",
  //       images: ["https://via.placeholder.com/640"],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "Loving this!",
  //       likes: 100,
  //     },
  //     {
  //       id: 2,
  //       user: "jane_doe",
  //       images: ["https://via.placeholder.com/640"],
  //       profilePic: "https://via.placeholder.com/150",
  //       caption: "Loving this!",
  //       likes: 100,
  //     },
  //     // Add more posts as needed
  //   ];

  const UserInfo = ({ isOwner, isCreator, username, profilePic }) => {
    return (
      <div className="flex justify-between items-center p-3 pb-1">
        <img
          src={profilePic}
          alt={username}
          className="rounded-full w-8 h-8 md:w-10 md:h-10"
        />
        <div className="flex items-center">
          {isCreator && (
            <div class="flex items-center mt-2 space-x-1 mr-2">
              <span
                class={`inline-block px-2 py-1 text-xs font-bold rounded-full bg-blue-200 text-black mb-2`}
              >
                Creator
              </span>
            </div>
          )}
          {isOwner && (
            <div class="flex items-center mt-2 space-x-1 mr-2">
              <span
                class={`inline-block px-2 py-1 text-xs font-bold rounded-full bg-green-200 text-black mb-2`}
              >
                Owned
              </span>
            </div>
          )}
          <Link
            to={`/${username}`}
            className="text-black font-medium text-sm md:text-base"
          >
            {username}
          </Link>
        </div>

        <FaEllipsisH className="text-grey text-sm md:text-base" />
      </div>
    );
  };

  const AssetCard = ({ asset }) => {
    const [isLiked, setIsLiked] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [buying, setBuying] = useState(false);

    const toggleLike = () => setIsLiked(!isLiked);
    const toggleBookmark = () => setIsBookmarked(!isBookmarked);

    const createINft = async (assetId) => {
      try {
        console.log('creating inft: ', assetId);
        let assetBody = {
          name: asset.name,
          tokenId: 1,
          assetId: assetId,
          nftInfo: {
            network: {
              tokenId: 1,
              mintNetworkType: 'fiat',
              mintPaymentMethod: 'Stripe',
            },
          },
        };
        const response = await create(assetBody, token);
        if (response.data) {
          console.log({ response });
          return response.data;
        }
      } catch (error) {
        console.log({ error });
        return null;
      }
    };

    const handleView = () => {
      console.log('Viewing asset: ', asset);
      navigate(`/iasset/${asset.id}`);
    };

    const handleSubmit = async () => {
      setBuying(true);
      let inft = await createINft(asset.id);
      if (inft) {
        await initOwned();
        updatePublishedAssets();
      }
      setBuying(false);
    };

    return (
      <div className="w-full max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-2">
        <UserInfo
          username={asset.creatorName}
          isCreator={asset.isCreator}
          isOwner={asset.isOwner}
          profilePic="https://via.placeholder.com/150"
        />
        <div>
          {asset.images.length > 1 ? (
            <Swiper images={asset.images} />
          ) : (
            <img
              className="w-full object-cover object-center h-auto md:h-96 mt-2"
              src={asset.images[0]}
              alt="Post content"
            />
          )}
        </div>
        <div className="flex justify-between items-center p-3">
          <div className="flex space-x-4">
            <InteractiveIcon
              icon={FaRegHeart}
              activeIcon={FaHeart}
              isActive={isLiked}
              onToggle={toggleLike}
              toggleText="Like"
            />
            <InteractiveIcon
              icon={FaRegComment}
              activeIcon={FaRegComment}
              toggleText="Comment"
            />
            <InteractiveIcon
              icon={FaPaperPlane}
              activeIcon={FaPaperPlane}
              toggleText="Share"
            />
          </div>
          <div className="flex items-center">
            {!asset.isOwner && (
              <div className="mr-2">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg mt-4"
                  onClick={() => handleView()}
                >
                  View
                </button>
              </div>
            )}
            <InteractiveIcon
              icon={FaRegBookmark}
              activeIcon={FaBookmark}
              isActive={isBookmarked}
              onToggle={toggleBookmark}
              toggleText="Bookmark"
            />
          </div>
        </div>
        <div className="px-3 py-1">
          <Link to="#" className="font-bold text-sm md:text-base text-darkgrey">
            {asset.likes} likes
          </Link>
        </div>
        <div className="px-3 py-2">
          <p className="text-darkgrey text-sm md:text-base">
            {asset.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center p-4 overflow-hidden mb-10">
      {publishedAssets.map((asset) => (
        <AssetCard key={asset.id} asset={asset} />
      ))}
    </div>
  );
}

export default HomePage;
