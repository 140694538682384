import React, { useState, useEffect, useRef, Component } from 'react';
import {
  FaTrash,
  FaSpinner,
  FaExclamationTriangle,
  FaChevronDown,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import store from '../stores';
import { setToken, setUsername, setUserId } from '../stores/UserStore';
import FileUploader from '../components/FileUploader';

import {
  deleteComponent,
  getAllComponentsByCreatorId,
} from '../server/components';

export default function Components() {
  const [isUploadPanelVisible, setUploadPanelVisible] = useState(true);

  const [components, setComponents] = useState([]);

  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    init();
    refresh();
  }, []);

  useEffect(async () => {
    if (token != null && userId != null) {
      await getComponents();
    }
  }, [token, userId]);

  async function init() {
    //check if mobile, if so, hide upload panel
    if (window.innerWidth < 768) {
      setUploadPanelVisible(false);
    }
    loadFromCache();
  }

  function loadFromCache() {
    let cacheLogin = localStorage.getItem('loggedInUser');
    if (cacheLogin) {
      cacheLogin = JSON.parse(cacheLogin);
      store.dispatch(setUserId(cacheLogin.userId));
      store.dispatch(setUsername(cacheLogin.username));
      store.dispatch(setToken(cacheLogin.token));
    }
  }

  async function refresh() {
    await getComponents();
  }

  const getComponents = async () => {
    try {
      if (userId == null) return;
      const response = await getAllComponentsByCreatorId(userId);
      //   console.log({ response });
      if (response.data) {
        let components = response.data.data;
        //reverse the order of components
        components = components.reverse();
        // console.log(components);
        components = components.map((comp) => {
          return {
            id: comp._id,
            name: comp.name,
            type: comp.type,
            previewImage: comp.rawFileUrl,
          };
        });
        setComponents(components);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const updateLibrary = async () => {
    await getComponents();
  };

  const ComponentCard = ({ component }) => {
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState(false);
    const onDelete = async (component) => {
      setDeleting(true);
      try {
        const response = await deleteComponent(component.id, token);
        if (response.status === 200) {
          refresh();
          setDeleting(false);
        } else {
          console.log({ response });
          setDeleting(false);
          setError(true);
        }
      } catch (error) {
        console.log({ error });
        setDeleting(false);
        setError(true);
      }
    };
    return (
      <div className="flex flex-col bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition w-full">
        <div class="flex items-center justify-between">
          <div class="flex items-center mt-2 space-x-1">
            <span
              class={`inline-block px-2 py-1 text-xs font-bold rounded-full bg-gray-200 text-black mb-2`}
            >
              {component.type}
            </span>
          </div>
          <>
            {deleting ? (
              <FaSpinner className="text-xl md:text-xl animate-spin" />
            ) : error ? (
              <FaExclamationTriangle className="text-xl md:text-xl" />
            ) : (
              <button
                className="m-2 bg-red-500 text-white font-bold py-1 px-2 rounded focus:outline-none hover:bg-red-600"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(component);
                }}
              >
                <FaTrash className="text-xl md:text-xl" />
              </button>
            )}
          </>
        </div>
        <img
          className="w-full h-32 object-cover rounded-lg mb-4"
          src={component.previewImage}
          alt={`${component.name} Preview`}
        />
        <h3 className="text-lg font-semibold mb-2 lg:w-64 w-48 truncate">
          {component.name}
        </h3>
      </div>
    );
  };

  const CollapsiblePanel = ({ onProcessed }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="mb-4 min-w-[300px]">
        <div className="flex justify-between p-4 bg-gray-100 rounded-t-lg">
          <span className="text-lg font-semibold">Upload Files</span>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FaChevronDown
              className={`transform transition-transform ${
                isOpen ? 'rotate-180' : ''
              }`}
            />
          </button>
        </div>
        {isOpen && (
          <div className="p-4 bg-white border border-t-0 rounded-b-lg">
            <FileUploader onProcessed={onProcessed} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 h-full">
      <div className="flex flex-col md:flex-row h-full">
        <div className="mb-4 md:mb-0 sm:w-100">
          <CollapsiblePanel onProcessed={updateLibrary} />
        </div>
        <div className="flex-1 overflow-auto">
          <div className={`p-2 grid gap-4 h-full md:col-span-3`}>
            <div className="text-xl font-semibold mb-0 sm:mb-4">
              Components Library
            </div>

            <div className="p-4 bg-white border rounded-lg shadow-md max-h-[500px] overflow-y-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
                {components.map((comp) => (
                  <div className="bg-white">
                    <ComponentCard component={comp} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
