import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/component`;

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const processRawFile = (rawFileId, token) =>
  axios.post(`${baseUrl}/process`, { rawFileId: rawFileId }, headers(token));

export const processAndUploadRawFile = (file, token) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${baseUrl}/process/upload`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateComponent = (componentId, component, token) =>
  axios.put(`${baseUrl}/update/${componentId}`, component, headers(token));

export const deleteComponent = (componentId, token) =>
  axios.delete(`${baseUrl}/delete/${componentId}`, headers(token));

export const getComponent = (componentId, token) =>
  axios.get(`${baseUrl}/get/${componentId}`, headers(token));

export const getAllComponentsByCreatorId = (creatorId) =>
  axios.get(`${baseUrl}/creator/${creatorId}`);
