import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/account`;

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const login = async (params) => {
  try {
    let url = `${baseUrl}/login`;
    let body = {
      username: params.username,
      password: params.pwd,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return { error: error.response.data.message };
    }
    return { error: "An unexpected error occurred" };
  }
};

const register = async (body) => {
  try {
    let url = `${baseUrl}/register`;
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return { error: error.response.data.message };
    }
    return { error: "An unexpected error occurred" };
  }
};

export { login, register };
