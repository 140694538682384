import React, { useState, useEffect, useRef } from 'react';
import Header from './components/Header';
export default function MainLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header onToggleSidebar={handleToggleClick} />
        {/* <Sidebar sidebarOpen={sidebarOpen} /> */}
        <div class="p-1 mt-14 flex-grow">{children}</div>
      </div>
    </>
  );
}
